import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import mapStyle from './map.module.scss';
import Marker from "./marker/marker";
import placesIVisited from './places-i-visited';

interface MapProps {}

const Map: React.FunctionComponent<MapProps> = () => {
  return (
    <div className={mapStyle.mapWrapper}>
      <p>I love to travel. These are places that I adventured to.</p>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
        defaultCenter={{
          lat: 41,
          lng: -10
        }}
        defaultZoom={0}
      >
        {placesIVisited.map((item, index) => (
          <Marker
            key={index}
            lat={item.lat}
            lng={item.lng}
            name={item.name}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
