import * as React from 'react';
import markerStyle from './marker.module.scss';


interface MarkerProps {
  name: string,
  lat: number,
  lng: number,
}

const Marker: React.FunctionComponent<MarkerProps> = ({}) => {
  return (
    <div className={markerStyle.marker}/>
  );
};

export default Marker;
