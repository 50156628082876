interface Places {
  name: string,
  lat: number,
  lng: number,
  date: string
}

const placesIVisited: Places[] = [
  {
    lat: 40.7128,
    lng: -74.0060,
    date: "2013",
    name: "New York City, New York",
  },
  {
    lat: 37.5407,
    lng: -77.4360,
    date: "06/23/2014",
    name: "Richmond, Virginia"
  },
  {
    lat: 42.3601,
    lng: -71.0589,
    date: "02/14/2015",
    name: "Boston, Massachusetts"
  },
  {
    lat: 45.5051,
    lng: -122.6750,
    date: "06/01/2015",
    name: "Portland, Oregon"
  },
  {
    lat: 32.7767,
    lng: -96.7970,
    date: "07/05/2015",
    name: "Dallas, Texas"
  },
  {
    lat: 39.2904,
    lng: -76.6122,
    date: "08/16/2015",
    name: "Baltimore, Maryland"
  },
  {
    lat: 38.4755,
    lng: -78.4535,
    date: "09/11/2015",
    name: "Shenandoah, Virginia"
  },
  {
    lat: 35.2271,
    lng: -80.8431,
    date: "09/12/2015",
    name: "Charlotte, North Carolina"
  },
  {
    lat: 33.6891,
    lng: -78.8867,
    date: "09/16/2015",
    name: "Atlanta, Georgia"
  },
  {
    lat: 33.7490,
    lng: -84.3880,
    date: "09/16/2015",
    name: "Myrtle Beach, South Carolina"
  },
  {
    lat: 35.5585,
    lng: -75.4665,
    date: "09/17/2015",
    name: "Outer Banks, North Carolina"
  },
  {
    lat: 36.8529,
    lng: -75.9780,
    date: "09/17/2015",
    name: "Virginia Beach, Virginia"
  },
  {
    lat: 47.6062,
    lng: -122.3321,
    date: "07/04/2016",
    name: "Seattle, Washington"
  },
  {
    lat: 28.5383,
    lng: -81.3792,
    date: "07/16/2016",
    name: "Orlando, Florida"
  },
  {
    lat: 37.6872,
    lng: -97.3301,
    date: "10/14/2016",
    name: "Wichita, Kansas"
  },
  {
    lat: 10.8231,
    lng: 106.6297,
    date: "12/09/2016",
    name: "Saigon, Vietnam"
  },
  {
    lat: 15.4229,
    lng: 108.5938,
    date: "12/12/2016",
    name: "Nui Thanh District, Vietnam"
  },
  {
    lat: 15.8801,
    lng: 108.3380,
    date: "12/13/2016",
    name: "Hoi An, Vietnam"
  },
  {
    lat: 16.0544,
    lng: 108.2022,
    date: "12/14/2016",
    name: "Da Nang, Vietnam"
  },
  {
    lat: 11.4275,
    lng: 107.3612,
    date: "12/17/2016",
    name: "Tan Phu District, Vietnam"
  },
  {
    lat: 10.0452,
    lng: 105.7469,
    date: "12/21/2016",
    name: "Can Tho, Vietnam"
  },
  {
    lat: 10.4114,
    lng: 107.1362,
    date: "12/22/2016",
    name: "Vung Tau, Vietnam"
  },
  {
    lat: 1.3521,
    lng: 103.8198,
    date: "12/26/2016",
    name: "Singapore"
  },
  {
    lat: 37.3929,
    lng: -80.0367,
    date: "03/29/2017",
    name: "McAfee Knob, Virginia"
  },
  {
    lat: 24.5551,
    lng: -81.7800,
    date: "09/04/2017",
    name: "Key West, Florida"
  },
  {
    lat: 18.4655,
    lng: -66.1057,
    date: "03/04/2018",
    name: "San Juan, Puerto Rico"
  },
  {
    lat: 40.4406,
    lng: -79.9959,
    date: "09/22/2018",
    name: "Pittsburgh, Pennsylvania"
  },
  {
    lat: 38.9072,
    lng: -77.0369,
    date: "09/30/2018",
    name: "Washington DC"
  },
  {
    lat: 41.8781,
    lng: -87.6298,
    date: "10/07/2018",
    name: "Chicago, Illinois"
  },
  {
    lat: 38.2527,
    lng: -85.7585,
    date: "10/09/2018",
    name: "Louisville, Kentucky"
  },
  {
    lat: 34.0522,
    lng: -118.2437,
    date: "12/29/2018",
    name: "Los Angeles, California"
  },
  {
    lat: 37.7749,
    lng: -122.4194,
    date: "01/02/2019",
    name: "San Francisco, California"
  },
  {
    lat: 37.3382,
    lng: -121.8863,
    date: "01/03/2019",
    name: "San Jose, California"
  },
  {
    lat: 35.6532,
    lng: -83.5070,
    date: "03/11/2019",
    name: "Great Smoky Mountains, North Carolina"
  },
  {
    lat: 45.4215,
    lng: -75.6972,
    date: "05/18/2019",
    name: "Ottawa, Canada"
  },
  {
    lat: 43.6532,
    lng: -79.3832,
    date: "05/21/2019",
    name: "Toronto, Canada"
  },
  {
    lat: 45.5017,
    lng: -73.5673,
    date: "06/26/2019",
    name: "Montreal, Canada"
  },
  {
    lat: 44.2706,
    lng: -71.3033,
    date: "06/28/2019",
    name: "Mountain Washington, New Hampshire"
  },
  {
    lat: 35.7796,
    lng: -78.6382,
    date: "07/08/2019",
    name: "Raleigh, North Carolina"
  },
  {
    lat: 38.7223,
    lng: -9.1393,
    date: "06/17/2019",
    name: "Lisbon, Portugal"
  },
  {
    lat: 41.3851,
    lng: 2.1734,
    date: "06/20/2019",
    name: "Barcelona, Spain"
  },
  {
    lat: 47.3769,
    lng: 8.5417,
    date: "06/21/2019",
    name: "Zurich, Switzerland"
  },
  {
    lat: 47.0502,
    lng: 8.3093,
    date: "06/22/2019",
    name: "Lucerne, Switzerland"
  },
  {
    lat: 46.8382,
    lng: 8.1873,
    date: "06/22/2019",
    name: "Giswil, Switzerland"
  },
  {
    lat: 55.6761,
    lng: 12.5683,
    date: "06/23/2019",
    name: "Copenhagen, Denmark"
  },
  {
    lat: 60.1699,
    lng: 24.9384,
    date: "06/24/2019",
    name: "Helsinki, Finland"
  },
  {
    lat: 52.3667,
    lng: 4.8945,
    date: "06/25/2019",
    name: "Amsterdam, Netherlands"
  },
  {
    lat: 48.8566,
    lng: 2.3522,
    date: "06/27/2019",
    name: "Paris, France"
  },
  {
    lat: 41.1579,
    lng: -8.6291,
    date: "06/28/2019",
    name: "Porto, Portugal"
  },
  {
    lat: 34.5045,
    lng: -97.1195,
    date: "08/04/2019",
    name: "Davis, Oklahoma"
  },
  {
    lat: 39.7392,
    lng: -104.9903,
    date: "08/28/2019",
    name: "Denver, Colorado"
  },
  {
    lat: 36.1627,
    lng: -86.7816,
    date: "06/14/2020",
    name: "Nashville, Tennessee",
  },
  {
    lat: 43.6591,
    lng: -70.2568,
    date: "08/23/2020",
    name: "Portland, Maine",
  },
  {
    lat: 44.3386,
    lng: -68.2733,
    date: "08/27/2020",
    name: "Acadia National Park, Maine",
  },
  {
    lat: 42.0081,
    lng: -74.3474,
    date: "12/30/2020",
    name: "Wittenberg Mountain, New York",
  }
];

export default placesIVisited;
