import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Map from "../containers/map/map";

type MapPageProps = {};

const MapPage: React.FunctionComponent<MapPageProps> = () => {
    return (
        <Layout>
            <SEO
                title="Places I visited"
                description="Bao Pham's maps"
            />
            <Map />
        </Layout>
    );
};

export default MapPage;
